/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { Auth, AuthUser } from "@/store";
const http = axios.create({});
async function get() {
  // http.defaults.baseURL = process.env.VITE_API_BASE_URL ?? "http://localhost:3009/api";
   http.defaults.baseURL = process.env.VITE_API_BASE_URL ?? "https://api.diogogalhardo.com.br/api";
  // http.defaults.baseURL = process.env.VITE_API_BASE_URL ?? "http://143.198.247.106:3009/api";

  http.interceptors.request.use((config: any) => {
    if (config.headers === undefined) {
      return;
    }
    if (Auth.getters.getToken.value) {
      config.headers.Authorization = `Bearer ${Auth.getters.getToken.value}`;
    } else if (AuthUser.getters.getToken.value) {
      config.headers.Authorization = `Bearer ${AuthUser.getters.getToken.value}`;
    }
    return config;
  });

  http.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // Verifica se o erro é devido a um token expirado
      if (error?.response?.status === 401 && !originalRequest._retry) {
        // Lógica para realizar o refresh token
        try {
          if (Auth.getters.getToken.value) {
            await Auth.actions.refreshToken();
          } else if (AuthUser.getters.getToken.value) {
            await AuthUser.actions.refreshToken();
          }
          originalRequest._retry = true;
          // Reenvia a solicitação original com o novo token
          return http(originalRequest);
        } catch (refreshError) {
          // Se ocorrer um erro durante o refresh token, redirecione para a página de login ou trate de acordo com sua lógica
          // ...

          originalRequest._retry = false;
          return Promise.reject(refreshError);
        }
      }

      // Se não for um erro de token expirado, apenas rejeita a promessa com o erro original
      return Promise.reject(error);
    }
  );

  return http;
}

export { get };
