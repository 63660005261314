import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/components/pages/indexHome.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/components/pages/pagePrincipal.vue')
      },
      {
        path: '/aereo',
        name: 'Aéreo',
        component: () => import('@/components/pages/pageAereo.vue')
      },
      {
        path: '/aereo-detalhes',
        name: 'AéreoDetalhes',
        component: () => import('@/components/pages/pageDetalhesAereo.vue')
      },

      {
        path: '/carros',
        name: 'Carros',
        component: () => import('@/components/pages/pageCarros.vue')
      },
      {
        path: '/cruzeiros',
        name: 'Cruzeiros',
        component: () => import('@/components/pages/pageCruzeiros.vue')
      },
      {
        path: '/hoteis',
        name: 'Hoteis',
        component: () => import('@/components/pages/pageHoteis.vue')
      },

      {
        path: '/hoteis-detalhes',
        name: 'HoteisDetalhes',
        component: () => import('@/components/pages/pageDetalhesHoteis.vue')
      },

      {
        path: '/pacotes',
        name: 'Pacotes',
        component: () => import('@/components/pages/pagePacotes.vue')
      },

      {
        path: '/monte-pacotes',
        name: 'MontePacotes',
        component: () => import('@/components/pages/pageMontePacotes.vue')
      },
      {
        path: '/servico',
        name: 'Servico',
        component: () => import('@/components/pages/PageServico.vue')
      },
      {
        path: '/seguro-viagem',
        name: 'SeguroViagem',
        component: () => import('@/components/pages/PageSeguroViagem.vue')
      },

      {
        path: '/chip',
        name: 'Chip',
        component: () => import('@/components/pages/PageChip.vue')
      },

      {
        path: '/info-vale-presente',
        name: 'InfoValePresente',
        component: () => import('@/components/pages/PageInfoValePresente.vue')
      },
      {
        path: '/info-lista-de-presentes',
        name: 'ListaDePresentes',
        component: () => import('@/components/pages/PageInfoListaDePresentes.vue')
      },

      {
        path: '/conta-inter',
        name: 'ContaInter',
        component: () => import('@/components/pages/pageContaInter.vue')
      },

      {
        path: '/cambio-especie',
        name: 'CambioEspecie',
        component: () => import('@/components/pages/pageCambioEpecie.vue')
      },
      {
        path: '/cambio-cartao',
        name: 'CambioCartao',
        component: () => import('@/components/pages/pageCambioCartao.vue')
      },
      {
        path: '/cambio-remessas',
        name: 'CambioRemessas',
        component: () => import('@/components/pages/pageCambioRemessa.vue')
      },
      {
        path: '/western-union',
        name: 'WesternUnion',
        component: () => import('@/components/pages/pageWesternUnion.vue')
      },
      {
        path: '/trabalhe-conosco',
        name: 'TrabalheConodco',
        component: () => import('@/components/pages/PageTrabalheConosco.vue')
      },
      {
        path: '/faq',
        name: 'Faq',
        component: () => import('@/components/pages/pageFaq.vue')
      },
      {
        path: '/app',
        name: 'App',
        component: () => import('@/components/pages/PageApp.vue')
      },
      {
        path: '/checkout',
        name: 'PackgeCheckout',
        component: () => import('@/components/pages/pagePackgeCheckout.vue')
      },
      {
        path: '/detalhes-pacotes/:id',
        name: 'DetalhesPacotes',
        component: () => import('@/components/pages/pageDetalhesPacotes.vue')
      },
      {
        path: '/detalhes-lojas/:id',
        name: 'DetalhesLojas',
        component: () => import('@/components/pages/pageDetalhesLojas.vue')
      },
      {
        path: '/quem-somos',
        name: 'QuemSomos',
        component: () => import('@/components/pages/pageQuemSomos.vue')
      },
      {
        path: '/nossas-lojas',
        name: 'NossasLojas',
        component: () => import('@/components/pages/PageNossasLojas.vue')
      },
      {
        path: '/contato',
        name: 'Contato',
        component: () => import('@/components/pages/PageContato.vue')
      },
    ]
  },
  {
    path: '/PageTesteIframe',
    name: 'TesteIframe',
    component: () => import('@/components/pages/PageTesteIframe.vue')
  },
  {
    path: '/client/',
    name: 'Client',
    component: () => import('@/components/models/NavBarClient.vue'),
    children: [
      {
        path: '/home-cliente',
        name: 'HomeClientes',
        component: () => import('@/components/client/PageHomeCliente.vue')
      },
      {
        path: '/client/minhas-compras',
        name: 'MinhaViagens',
        component: () => import('@/components/client/PageMinhasViagens.vue')
      },
      {
        path: '/client/financeiro',
        name: 'FinanceiroCliente',
        component: () => import('@/components/client/PageFinanceiroCliente.vue')
      },
      {
        path: '/client/convidados',
        name: 'Convidados',
        component: () => import('@/components/client/PageConvidados.vue')
      },
      {
        path: '/client/vale-presente',
        name: 'ClientValePresente',
        component: () => import('@/components/client/PageValePresente.vue')
      },
      {
        path: '/client/info-lista-de-presentes',
        name: 'ClientInfoListaDePresentes',
        component: () => import('@/components/client/PageClienteListaDePresentesInfo.vue')
      },
      {
        path: '/client/lista-de-presentes',
        name: 'ClientListaDePresentes',
        component: () => import('@/components/client/PageListaDePresentes.vue')
      },

      {
        path: '/client/meus-dados',
        name: 'ClientDados',
        component: () => import('@/components/client/meusDadosCliente.vue')
      }
    ]
  },
  {
    path: '/convite/:id',
    name: 'Convite',
    component: () => import('@/components/client/PageConvite.vue')
  },
  {
    path: '/convitePreview/:id',
    name: 'ConvitePreview',
    component: () => import('@/components/client/PageConvitePreview.vue')
  },
  {
    path: '/convitein/:id',
    name: 'ConviteUni',
    component: () => import('@/components/client/PageConviteOne.vue')
  },
  {
    path: '/conviteinPreview/:id',
    name: 'ConviteUniPreview',
    component: () => import('@/components/client/PageConviteOnePreview.vue')
  },
  {
    path: '/esqueceu-senha',
    name: 'EsqueceuSenha',
    component: () => import('@/components/admin/PageRememberPassword.vue')
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import('@/components/admin/PageLogin.vue')
  },
  {
    path: '/admin',
    beforeEnter: (to, from, next) => {
      console.log('Token:', AuthUser.getters.getToken.value);
      if (to.path === '/admin/dashboard') {
        next();
      } else if (AuthUser.getters.getToken.value) {
        console.log('Usuário autenticado. Redirecionando para /admin/dashboard');
        next('/admin/dashboard');
      } else {
        console.log('Usuário não autenticado. Redirecionando para /admin/login');
        next('/admin/login');
      }
    },
    name: 'Admin',
    component: () => import('@/components/admin/pages/PageAdmin.vue',),
    children: [
      {
        path: '/admin/cadastro',
        name: 'Cadastro',
        component: () => import('@/components/admin/models/ModelCadastro.vue',),
      },
      {
        path: '/admin/ajuda',
        name: 'Ajuda',
        component: () => import('@/components/admin/models/ModelAjuda.vue',),
      },
      {
        path: '/admin/curriculo',
        name: 'Curriculo',
        component: () => import('@/components/admin/models/ModelCurriculo.vue',),
      },
      {
        path: '/admin/dashboard',
        name: 'Dashboard',
        component: () => import('@/components/admin/models/ModelDashboard.vue',),
      },
      {
        path: '/admin/destaque',
        name: 'Destaque',
        component: () => import('@/components/admin/models/ModelDestaque.vue',),
      },
      {
        path: '/admin/central-de-ajuda',
        name: 'CentralDeAjuda',
        component: () => import('@/components/admin/models/ModelCentraldeAjuda.vue',),
      },
      {
        path: '/admin/trabalhe-conosco',
        name: 'TrabalheConosco',
        component: () => import('@/components/admin/models/ModelTrabalheConosco.vue',),
      },
      {
        path: '/admin/popup',
        name: 'Popup',
        component: () => import('@/components/admin/models/ModelPopUp.vue',),
      },
      {
        path: '/admin/cupom-seguro-viagem',
        name: 'CupomSeguroViagem',
        component: () => import('@/components/admin/models/ModelCupomSeguro.vue',),
      },
      {
        path: '/admin/configuracoes',
        name: 'Configuracoes',
        component: () => import('@/components/admin/models/ModelConfiguracoes.vue',),
      },
      {
        path: '/admin/minhas-reservas',
        name: 'MinhasReservas',
        component: () => import('@/components/admin/models/ModelMinhasReservas.vue',),
      },
      {
        path: '/admin/financeiro/resumo-vale-presente/:id',
        name: 'ResumoValePresente',
        component: () => import('@/components/admin/models/ModelResumoValePresente.vue',),
      },
      {
        path: '/admin/financeiro/resumo-pacotes/:id',
        name: 'ResumoPacotes',
        component: () => import('@/components/admin/models/ModelResumoPacotes.vue',),
      },
      {
        path: '/admin/financeiro/resumo-Lista-de-Presentes/:id',
        name: 'ResumoListaDePresentes',
        component: () => import('@/components/admin/models/ModelResumoListaDePresentes.vue',),
      },
      {
        path: '/admin/contato',
        name: 'AdminContato',
        component: () => import('@/components/admin/models/ModelContato.vue',),
      },
      {
        path: '/admin/western',
        name: 'AdminWestern',
        component: () => import('@/components/admin/models/ModelWesternUnion.vue',),
      },
      {
        path: '/admin/pacotes',
        name: 'PacotesAdmin',
        component: () => import('@/components/admin/models/ModelPacotes.vue',),
      }
      ,
      {
        path: '/admin/news',
        name: 'Newsletter',
        component: () => import('@/components/admin/models/ModelNews.vue',),
      }
      ,
      {
        path: '/admin/financeiro',
        name: 'Financeiro',
        component: () => import('@/components/admin/models/ModelFinanceiro.vue',),
      }
      ,
      {
        path: '/admin/compra',
        name: 'Compra',
        component: () => import('@/components/admin/models/ModelCompra.vue')
      }
      ,
      {
        path: '/admin/vale-presente',
        name: 'ValePresente',
        component: () => import('@/components/admin/models/ModelValePresente.vue',),
      },
      {
        path: '/admin/perfil',
        name: 'Perfil',
        component: () => import('@/components/admin/models/ModelPerfil.vue',),
      }
      ,
      {
        path: '/admin/users',
        name: 'Usuarios',
        component: () => import('@/components/admin/models/ModelUsers.vue',),
      }
      ,
      {
        path: '/admin/lojas',
        name: 'Lojas',
        component: () => import('@/components/admin/models/ModelLojas.vue',),
      }
      ,
      {
        path: '/admin/lista-de-presentes',
        name: 'listaDePresentes',
        component: () => import('@/components/admin/models/ModelListaDePresentes.vue',),
      },
      {
        path: '/admin/meus-dados',
        name: 'MeusDados',
        component: () => import('@/components/admin/models/ModelMeusDados.vue',),
      },
      {
        path: '/admin/alterar-senha',
        name: 'AlterarSenha',
        component: () => import('@/components/admin/models/ModelAlterarSenha.vue',),
      },
    ]
  },
  { path: '/:catchAll(.*)*', component: () => import('@/components/pages/pageErro404.vue') }
]
import { Auth, AuthUser, Site } from '@/store'
import { openModalLogin } from '@/store/modules/site';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {

  await Site.actions.ActionCheckConfig();

  if (to.fullPath.includes("client")) {
    await Auth.actions.ActionCheckToken();

    if (!Auth.getters.getToken.value) {
      openModalLogin.value = true;
      return next("/")
    }

  }

  if (to.fullPath.includes("admin")) {
    await AuthUser.actions.ActionCheckToken();

    if (!AuthUser.getters.getToken.value && !to.fullPath.includes("/admin/login")) {
      return next("/admin/login")
    }

  }
  next(true);
})

export default router
